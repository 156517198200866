
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import { plainToInstance } from 'class-transformer'
  import { Financing } from '@/entities/loans'
  import { Form } from '@/entities/public/Resource/metadata'
  import { Lead } from '@/entities/crm'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import { fixPrice, parseToNumber, sendNotification, updateNestedObject } from '@/utils/general'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import StockPrices from '@/components/toolkit/details/row/custom/StockPrices.vue'
  import AutoHeader from '@/components/toolkit/details/row/expandable/auto/header.vue'
  import Simple from '@/components/toolkit/details/row/simple.vue'
  import { LeadView } from '@/components/forms/view/LeadView'
  import { Person } from '@/entities/persons'
  import LaborDataContent from '@/components/person/laborData/LaborDataContent.vue'
  import { Debounce } from '@/utils/decorators'
  import GAlert from '@/components/core/alert/GAlert.vue'

@Component({
  components: {
    GAlert,
    LaborDataContent,
    Simple,
    StockPrices,
    FieldTitle,
    LinkedPerson,
    InitialFields,
    LinkedAuto,
    BaseForm,
    AutoHeader,
  },
})
  export default class FinancialForm extends LeadView {
  financing = plainToInstance(Financing, {})
  lead = plainToInstance(Lead, {})
  title = ''
  errorApplicant = ''
  showDetail = false
  showLaborInfo = false
  metadata = {}
  leadMetadata = {}
  formData = {
    addTransfer: true,
    pie: null,
    applicant: null,
  }

  alert = {
    open: false,
    title: '',
  }

  fields = {
    applicant: {
      properties: {
        properties: {},
      },
      items: [],
      rules: [],
    },
    addTransfer: {
      properties: {
        label: '',
      },
    },
    initial: {
      properties: {
        label: '',
      },
    },
  }

  declare $refs: {
    form: HTMLFormElement
    fieldApplicant: LinkedPerson
  };

  async mounted () {
    await this.setMetadata()

    const { uid, model, title, metadata, financing, backup } = this

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadata, title, Boolean(financing?.id))
    }

    if (uid && model) {
      await this.getLeadInfo(uid)
    }

    if (!backup && !uid) {
      await this.close()
      return
    }
    await this.setTheBackup()
  }

  async getLeadInfo (id) {
    const lead = await this.fetchData({
      query: { name: 'fetch', model: 'Lead', params: { id } },
      force: true,
    })
    const { backup } = this

    if (!lead?.sale && !backup) {
      await this.close()
      return
    }
    if (!lead?.sale) {
      return
    }

    this.lead = lead

    const stock = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: { _and: [{ stock_detail: { id_auto: { _eq: lead.sale.auto.id } } }] },
    })
    const stockDetail = await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { id_stock: { _eq: stock[0].id } },
    })

    this.lead.sale.stock = stock[0]
    this.lead.sale.stock.viewDetails = stockDetail[0]
    this.lead.sale.stock.transferCost = await this.calculateTransfer(stock[0]?.id)
  }

  get bindApplicant () {
    const { fields: { applicant } } = this
    if (!applicant) return {}

    return { ...applicant.properties, items: applicant.items }
  }

  get initial () {
    const { formData } = this

    return formData.pie?.amount ?? 0
  }

  get total () {
    const { backup, lead, discount } = this

    if (!lead?.sale?.stock) return 0

    const total = lead.sale?.stock?.price?.amount ? lead.sale?.stock?.price?.amount : backup?.stock?.currentPrice?.amount
    return (total ?? 0) - (discount ?? 0)
  }

  get disableFinancing () {
    const { financing } = this

    return Boolean(financing?.id)
  }

  get initialValue () {
    const { initial, total } = this
    if (!total) return 0
    return (initial / total) * 100
  }

  get maxPie () {
    const { total, leadMetadata } = this

    if (!total || !(leadMetadata && 'fields' in leadMetadata)) return 0

    const { fields: { config: { init: { value: { maxPie } } } } } = leadMetadata as Form

    return (total - maxPie)
  }

  get isSaleOrder () {
    const { lead } = this
    return Boolean(lead?.sale)
  }

  get stockPricesLabels () {
    const { leadMetadata } = this

    if (!leadMetadata) return {}

    const { details: { children } } = leadMetadata as Form

    const stockField = children.find(child => child.component === 'stock')

    return stockField?.properties?.labels
  }

  get sale () {
    const { lead } = this

    if (!lead?.sale?.stock?.enablement) return null
    return lead?.sale
  }

  get kmFinancial () {
    const { sale } = this
    if (!sale) return ''
    const { stock: { viewDetails: { attributes } } } = sale
    const attribute = attributes?.find(attr => attr.component.name === 'Kilometraje')

    let kms = attribute?.value ? Number(attribute.value) : ''
    if (typeof kms === 'number') {
      kms = kms.toLocaleString('de-DE')
    }
    return kms
  }

  get auto () {
    const { sale } = this

    return sale?.auto
  }

  get status () {
    const { auto } = this
    if (!auto) return null
    const { status } = auto

    return {
      value: status.description,
      color: status.color,
    }
  }

  get header () {
    const { auto } = this
    if (!auto) return null
    const { title } = auto
    return `${title}`
  }

  get subHeader () {
    const { auto } = this
    if (!auto) return null
    const { version: { version: { name } } } = auto
    return name
  }

  get discount () {
    const { backup, lead } = this

    const discount = lead?.sale?.order?.saleOrderItems?.filter(saleOrderItem => saleOrderItem?.isDiscount)
    if (discount?.length && discount[0]?.amount) {
      return Math.abs(discount[0]?.amount)
    }
    return backup?.discount || 0
  }

  get discountLabel () {
    const { discount } = this

    return discount ? fixPrice(discount) : ''
  }

  get calculateLabel () {
    const { formData: { addTransfer } } = this

    return addTransfer ? `Monto solicitado más traspaso` : `Monto solicitado`
  }

  get calculateAmount () {
    const { transferAmount } = this
    return fixPrice(transferAmount)
  }

  get transferAmount () {
    const { formData: { addTransfer }, total, formData, transfer } = this
    return addTransfer ? ((total - formData?.pie?.amount) + transfer) : (total - formData?.pie?.amount)
  }

  get transfer () {
    const { lead } = this

    return lead?.sale?.stock?.transferCost || 0
  }

  async setTheBackup () {
    const { backup, uid, id } = this

    if (!backup) return

    const isFinancialForm = 'financialForm' in backup && backup.financialForm
    const isLeadForm = 'lead' in backup && backup.lead
    const isApplicant = 'applicant' in backup && backup.applicant

    if (!isLeadForm && !uid) {
      await this.close()
      return
    }

    if (isFinancialForm) {
      this.formData = backup.financialForm
      if (isApplicant) {
        this.formData.applicant = backup.applicant
        this.fields.applicant.items = [backup.applicant]
      }
    }

    if (isLeadForm) {
      this.lead = backup.lead
      this.fields.applicant.items = !isApplicant || !id ? [backup.lead.client] : []
    }

    if (isApplicant && id) {
      this.formData.applicant = backup.applicant
      this.fields.applicant.items = [backup.applicant]
      await this.validateApplicantInfo(backup.applicant)
    }
  }

  saveBackup () {
    const { backup, formData, transferAmount } = this

    if (backup) {
      backup.financialForm = { ...formData, transfer: transferAmount }
      this.setBackup(backup)
    } else {
      this.setBackup({ financialForm: { ...formData, transfer: transferAmount } })
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('Financing', 'financing')
    const { metadata: leadMetadata } = this.getForm('Lead', 'lead')
    this.leadMetadata = leadMetadata
    const { fields, form } = metadata as Form
    this.metadata = metadata
    this.title = form.title
    Object.assign(this.fields.applicant?.properties, fields.applicant?.properties)
    Object.assign(this.fields.addTransfer.properties, fields.addTransfer?.properties)
    Object.assign(this.fields.initial.properties, fields.initial?.properties)
  }

  async goPerson () {
    const { backup, formData } = this

    if (!backup) {
      this.setBackup({ financialForm: formData })
    } else {
      backup.financialForm = formData
      this.setBackup(backup)
    }
    const idPerson = formData.applicant?.id
    await this.$router.push({ name: 'generic-person-nested', params: { model: 'Financing', uid: null, id: idPerson } })
  }

  async searchPerson (data) {
    if (!data?.input?.length || data?.input?.length < 2) return null

    const { metadata } = this
    if (!metadata) return null
    const { fields } = metadata as Form
    const query = updateNestedObject(fields.applicant.computed.queries.items.where, '_eq', data.input, ['name_person_type'])

    this.fields.applicant.items = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { ...query },
    })
  }

  @Watch('formData.applicant', { immediate: true, deep: true })
  @Debounce(1000)
  async onChangeApplicant (applicant) {
    if (applicant?.id) {
      await this.validateApplicantInfo(applicant)
    } else {
      this.errorApplicant = ''
    }
  }

  confirmAction () {
    this.alert.open = false
    this.alert.title = ''
  }

  async validateApplicantInfo (applicant: Person) {
    const person = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: applicant.id } },
      force: true,
    })

    const errores = []

    if (!person?.uid) {
      errores.push('rut')
    }

    if (!person?.country) {
      errores.push('nacionalidad')
    }

    if (!person?.birthDate) {
      errores.push('fecha de nacimiento')
    }

    if (!person?.laborData?.length) {
      this.showLaborInfo = true
      errores.push('información laboral')
    }

    this.errorApplicant = errores.length ? `Solicitante le falta: ${errores.join(', ')}` : ''
  }

  @Watch('formData', { immediate: true, deep: true })
  onChangeData (val) {
    this.disableSend = false

    const { lead, total } = this

    if (!lead?.sale) return

    const { order } = lead.sale
    if (!order) return
    const { financings } = order

    if (this.evaluationValidated(financings, val)) {
      return
    }

    if (val?.applicant?.id && val?.pie?.perc >= 20) {
      const isFinancings = this.getFinancings(val.applicant, order)
      const isEvaluation = this.getEvaluations(isFinancings)

      if (isEvaluation?.length) {
        const isConditioned = isEvaluation?.some(evaluation => evaluation.status.isConditioned && !evaluation.conditions.some(condition => condition.isIncreaseInitial))
        const resp = this.filteredEvaluation(isEvaluation, val.pie, total)
        if (resp || isConditioned) {
          sendNotification('No cumple las condiciones para crear esta solicitud', 'error')
          this.disableSend = true
        }
      }
    }
  }

  evaluationValidated (financings, val) {
    const isSameEvaluation = financings.some(financing => parseToNumber(financing.initial) === parseToNumber(val.pie.amount) && financing.applicant.id === val?.applicant?.id)

    if (isSameEvaluation) {
      sendNotification('Esta solicitud ya existe para este solicitante', 'error')
      this.disableSend = true
      return true
    }

    const isRejected = financings.some(financing =>
      financing.applicant?.id === val.applicant?.id && financing.evaluations.every(evaluation => evaluation?.status?.isRejected))

    if (isRejected) {
      sendNotification('Este solicitante ya tiene todas las solicitudes rechazadas', 'error')
      this.disableSend = true
      return true
    }

    const isConditioned = financings.some(financing =>
      financing.applicant?.id === val.applicant?.id && financing.evaluations.every(evaluation => evaluation?.status?.isConditioned && evaluation?.conditions?.every(condition => !condition.isIncreaseInitial)))

    if (isConditioned) {
      sendNotification('Este solicitante ya tiene todas las solicitudes condicionadas', 'error')
      this.disableSend = true
      return true
    }
    return false
  }

  async send () {
    const applicantError = Boolean(this.fields.applicant.properties?.properties['error-messages']?.length)
    const applicant = this.$refs.fieldApplicant
    const initial = this.formData.pie?.amount

    if (!initial) {
      this.alert.open = true
      this.alert.title = 'El monto inicial no puede ser 0'
      return
    }

    if (!this.$refs.form.validate() || applicantError) {
      return
    }
    if (applicant && !applicant.$refs.form.validate()) {
      return true
    }

    const { lead } = this

    if (lead?.id) {
      await this.handleFinancing()
    }

    this.saveBackup()
    await this.close()
  }

  async handleFinancing () {
    const { lead: { client, sale: { order, id: saleOrderId }, sale }, formData, total, initial, lead, backup } = this

    const isFinancial = Boolean(lead.sale?.financial?.id)
    if (isFinancial && saleOrderId && lead?.sale?.order?.financings?.length) {
      await this.insertNewFinancing(client, order, formData, total, sale)
    } else {
      if (saleOrderId) {
        const financing = await this.createFinancing(order, initial, total, formData.applicant, formData.addTransfer, this)
        this.lead.sale.order.financings = [financing]
        await this.pushData({ model: 'Lead', fields: { id: lead.id, id_pipeline: this.lead.computedPipeline } })
      } else {
        await this.createDeal(backup, total, lead, formData, initial)
      }
    }
  }

  async createDeal (backup, total, lead, formData, initial) {
    const leadFormData = {
      stock: backup.stock,
      channel: backup.channel,
    }
    const sale = await this.buildSale({ total, lead, formData: leadFormData })
    const fields = {
      ...sale,
      id_lead: lead.id,
    }
    const newSale = await this.pushData({ model: 'Deal', fields })
    const financing = await this.createFinancing(newSale.order, initial, total, formData.applicant, formData.addTransfer, this)
    newSale.order.financings = [financing]
    this.lead.sale.saleOrders = newSale.saleOrders
    await this.pushData({ model: 'Lead', fields: { id: lead.id, id_pipeline: this.lead.computedPipeline } })
  }

  async openLaborData () {
    this.saveBackup()
    await this.$router.push({
      name: 'person-labor-data',
      params: {
        model: 'Person',
        uid: this.formData.applicant.id.toString(),
        id: 'create',
      },
    })
  }

  async closeFinancial () {
    const { backup } = this
    if (backup?.financialForm) {
      backup.financialForm = null
      this.setBackup(backup)
    }

    await this.close()
  }
  }
